import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// components
import { Button, Card, Chip, Icon, Label } from "../../atoms";
export const CareHomeCard = ({ providerId = '', headerImageUrl = '', name = '', address = '', rating = '', specialisms = [], }) => {
    return (_jsx(CardItem, Object.assign({ to: `/directory?provider=1-${providerId}` }, { children: _jsxs(StyledCard, { children: [_jsx(CardHeader, { children: _jsx("img", { src: headerImageUrl, alt: 'header' }) }), _jsx(CardTitle, { children: name }), _jsxs(CardDescription, { children: [_jsx(SubTitle, { children: address }), _jsx(ChipWrapper, { children: specialisms.map((specialism) => (_jsx(Chip, { label: specialism }, specialism))) }), _jsxs(SubTitleWrapper, { children: [_jsx(Icon, { icon: 'star', fontSize: 16 }), _jsxs(SubTitle, { children: [`${rating} - Regulator's rating`, " "] })] })] }), _jsxs(CardFooter, { children: [_jsx(Button, Object.assign({ ariaLabel: 'i have secured this care home', onPress: () => { }, isFullWidth: true }, { children: "I have secured this Care Home" })), _jsx(Label, { id: 'care-home-confirmation', text: 'Confirm so we can guide on you on managing care effectively' })] })] }) })));
};
const StyledCard = styled(Card) `
  gap: 0;
  border: 0;
  padding: ${(props) => props.theme.spacing[32]};
  height: 100%;
  margin: ${(props) => props.theme.spacing[2]};
  background: none;
  min-width: 250px;
  max-width: 400px;
`;
const CardHeader = styled.div `
  min-height: 150px;
  height: 150px;
  max-height: 150px;
  display: flex;
  border-radius: 8px 8px 0 0;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    border-radius: 8px 8px 0 0;
  }
`;
const CardTitle = styled.div `
  margin-top: ${(props) => props.theme.spacing[32]};
  color: ${(props) => props.theme.color.neutral[100]};
  font: ${(props) => props.theme.typography.body.xl.semibold};
`;
const CardDescription = styled.div `
  gap: ${(props) => props.theme.spacing[16]};
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.color.neutral[40]};
  padding: ${(props) => props.theme.spacing[16]} 0;
  font: ${(props) => props.theme.typography.body.md.regular};
`;
const CardFooter = styled.div `
  padding-top: ${(props) => props.theme.spacing[24]};
  gap: ${(props) => props.theme.spacing[16]};
  display: flex;
  flex-direction: column;
`;
const CardItem = styled(Link) `
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  min-width: 250px;
  height: fit-content;
  max-height: fit-content;
  box-sizing: border-box;
  background: ${(props) => props.theme.color.neutral[10]};
  box-shadow: ${(props) => props.theme.elevation[1]};
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  border: 1px solid ${(props) => props.theme.color.neutral[40]};
  text-decoration: none;

  transition-duration: 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;
const ChipWrapper = styled.div `
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.spacing[8]};
`;
const SubTitleWrapper = styled.div `
  display: flex;
  align-items: center;
`;
const SubTitle = styled.div `
  margin-left: ${(props) => props.theme.spacing[8]};
`;
CareHomeCard.displayName = 'CareHomeCard';
export default CareHomeCard;
