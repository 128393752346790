import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
import { useMemo } from 'react';
// components
import { CareHomeCard } from "../../molecules";
import { Card, Title } from "../../atoms";
export const CareHomeOverview = ({ shortlistData }) => {
    // helper memos
    const formatAddress = useMemo(() => (address) => {
        return `${address.streetAddress1}, ${address.city}, ${address.postcode}`;
    }, []);
    return (_jsxs(StyledArticleOverview, { children: [_jsx(MainHeader, { children: _jsxs(Header, { children: [_jsx(Title, Object.assign({ isNoMargin: true }, { children: "Your Shortlist" })), shortlistData.items && (_jsx(Card, Object.assign({ color: 'yellow', isBordered: false, elevation: 0 }, { children: _jsxs(StyledText, { children: ["Your Care Expert has chosen the below care services to match your care needs.", ' '] }) })))] }) }), _jsx(Content, { children: shortlistData.items && shortlistData.items.length > 0 ? (shortlistData.items.map((item) => {
                    var _a;
                    return (_jsx(CareHomeCard, { providerId: item.providerID, headerImageUrl: (_a = item.photos) === null || _a === void 0 ? void 0 : _a.medium, name: item.name, address: item.address
                            ? formatAddress(item.address)
                            : 'Address not available', rating: item.rating ? item.rating.overallRating : 'No rating available', specialisms: item.specialisms }, item.providerID));
                })) : (_jsx(StyledText, { children: "No care homes available in your shortlist." })) })] }));
};
const StyledArticleOverview = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[36]};
  padding-bottom: ${(props) => props.theme.spacing[8]};
  padding-left: ${(props) => props.theme.spacing[4]};
  padding-right: ${(props) => props.theme.spacing[4]};
  overflow-x: visible;
  ${(props) => props.theme.breakpoint.sm(css `
      overflow-x: hidden;
    `)}
`;
const MainHeader = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[36]};
  width: 100%;
`;
const Description = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[80]};
`;
const Header = styled.div `
  display: flex;
  flex-direction: column;
`;
const Content = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[32]};
  flex-wrap: wrap;
`;
const StyledText = styled.div `
  font: ${(props) => props.theme.typography.body.md};
  color: ${(props) => props.theme.color.neutral[90]};
`;
CareHomeOverview.displayName = 'CareHomeOverview';
export default CareHomeOverview;
