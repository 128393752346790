// components
import { CareSearch as CareSearchPage } from '@karehero/llama';

// views
import Directory from 'directory/Directory';

// store
import { useGetShortlistDataBySessionQuery } from 'store/api/shortlist';

/**
 * CareSearch shows the care plan at home editor.
 */

const CareSearch = () => {
  // hooks
  const { data: careHomeShortlist } = useGetShortlistDataBySessionQuery();

  return (
    <>
      {careHomeShortlist && (
        <CareSearchPage shortlistData={careHomeShortlist} />
      )}
      <Directory />
    </>
  );
};

export default CareSearch;
